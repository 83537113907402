export const getSumCostBeforeSellNoRepair = (car) => {
    // ทุนรถก่อนขาย ที่ ไม่รวมราคารถ + vatรถ และไม่รวมค่าซ่อมรถ
    let cost_operation = car.cost_operation;
    let cost_service = car.cost_service;
    let cost_transport = car.cost_transport;
    let cost_porobor_lan = car.cost_porobor_lan;
    let cost_bangkhab_phasi_lan = car.cost_bangkhab_phasi_lan;
    let cost_bangkhab_xon_lan = car.cost_bangkhab_xon_lan;
    let cost_check_ton = car.cost_check_ton;
    let car_cost_expenses_other = car.car_cost_expenses_other + car.car_cost_other
    let car_cost_other_sum = car_cost_expenses_other + car.car_cost_commissions + car.car_cost_act + car.car_cost_tax

    car_cost_other_sum += Number(cost_operation);
    car_cost_other_sum += Number(cost_service);
    car_cost_other_sum += Number(cost_transport);
    car_cost_other_sum += Number(cost_porobor_lan);
    car_cost_other_sum += Number(cost_bangkhab_phasi_lan);
    car_cost_other_sum += Number(cost_bangkhab_xon_lan);
    car_cost_other_sum += Number(cost_check_ton);
    return car_cost_other_sum
}


export const getSumCostBeforeSell = (car) => {
    // ค่าใช้จ่ายซื้อรถเข้าและรวมค่าซ่อม ไม่รวมราคารถ+vatรถ
    let car_cost_repair = car.car_cost_repair;
    let car_cost_other_sum = getSumCostBeforeSellNoRepair(car)
    car_cost_other_sum += Number(car_cost_repair);
    return car_cost_other_sum
}

export const getProfitBeforeSell = (car) => {
    let car_cost_other_sum = getSumCostBeforeSellNoRepair(car)
    let profit = car.car_sale_price - (car.car_cost + car.vat_buy  + car_cost_other_sum + car.car_cost_repair)
    return profit
}


//ส่วนการขาย
export const getSumCostAfterSell = (car) => {
    //ค่าใช้จ่ายการขาย ทางเต็นท์รับผิดชอบ
    let car_external_commission = car.car_external_commission;
    let sales_commission = car.sales_commission;
    let additional_commission = car.additional_commission;
    let advertising_cost = car.advertising_cost;
    let delivery_fee = car.delivery_fee;
    let finance_fee = car.finance_fee;
    let promotion_fee = car.promotion_fee;
    let transfer_fee = car.transfer_fee;
    let cost_after_sales_service = car.cost_after_sales_service;
    let cost_phra = car.cost_phra;
    let cost_vat_and_property_tax = car.cost_vat_and_property_tax;

    let car_cost_after_sell = Number(car_external_commission) + Number(sales_commission) + Number(additional_commission)
                            + Number(advertising_cost) + Number(delivery_fee) + Number(finance_fee) + + Number(promotion_fee)
                            + Number(transfer_fee) + Number(cost_after_sales_service) + Number(cost_phra) + + Number(cost_vat_and_property_tax);
    return car_cost_after_sell

}

export const getSumCostSellPayByCustomer = (car_sell) => {
    //รวมค่าใช้จ่ายตอนขาย ที่ลูกค้าต้องจ่ายเพิ่มเอง
    //เกิดขึ้นแค่กรณี ยอดจัดเกิน
    let car_excess_amount = car_sell.car_excess_amount
    let car_excess_amount_use = 0
    if(car_excess_amount > 0) {
        car_excess_amount_use = car_sell.pay_advance_finance + car_sell.gearbox_amount +
            car_sell.debt_insurance_amount + car_sell.cost_finance + car_sell.cost_sell_act + car_sell.total_outof_discount

        if(car_sell.insurance_buy === 'ซื้อเอง'){
            car_excess_amount_use += car_sell.insurance_price
        }
        if(car_sell.insurance_pa_buy === 'PA ซื้อเอง'){
            car_excess_amount_use += car_sell.insurance_pa
        }
    }

    return car_excess_amount_use

}

